import request from 'utils/request'

export class SmsApi {
  /**
   * @description 发送验证码
   * @params { actionType, phoneNumber, userId?  }
   * @returns { null }
   */
  static send (params) {
    return request.get('/sms/send', { params })
  }
}

export class UploadApi {
  /**
   * @description 通用上传请求
   * @params { files  }
   * @returns { Array }
   */
  static file (data) {
    return request.post(
      '/file/upload',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  }
  // 七牛云token
  static getUploadToken (params) {
    return request.get('/oss/qiniu/getUploadToken', { params })
  }
}

export class AvatarApi {
  /**
   * @description 查询用户头像列表
   * @params null
   * @returns { Array }
   */
  static list () {
    return request.get('/avatar/list')
  }
}

export class QuestionApi {
  /**
   * @description 提交问卷调查
   * @params { content，openId }
   * @returns null
   */
  static questionnaire (data) {
    return request.post('/questionnaire', data)
  }
}

// 统计
export class statisticsApi {
  /**
   * @description 新增访问记录
   * @params { type } 访问功能类型type  1.知识热点 2.健康管理 3.线下活动 4.活动签到 5.视频列表 6.视频详情7.文章列表 8.文章详情 9.患者故事 10.指标记录 11.运动打卡 12.运动统计 13.用药提醒 14.用药说明书 15.医保计算器
   * @returns null
   */
  static accessRecord (data) {
    return request.post('/accessRecord', data)
  }
}

export class CodeApi {
  /**
   * @description 提交问卷调查
   * @params { content，openId }
   * @returns null
   */
  static getCodeUrl (id) {
    return request.get('channel/' + id)
  }
}

