import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant, { Lazyload } from 'vant'
import VuewechatTitle from 'vue-wechat-title'

import SvgIcon from 'components/SvgIcon/index.vue'
import 'amfe-flexible'
import 'virtual:svg-icons-register'
import './style/index.less'

const app = createApp(App).use(store).use(router).use(vant).use(Lazyload).use(VuewechatTitle).component('svg-icon', SvgIcon)

app.config.ignoredElements = ['wx-open-subscribe']

app.mount('#app')
