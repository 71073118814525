import { defineStore } from 'pinia'
import { format, addDays } from 'date-fns'
import UserApi from 'api/user'
import { ShortVideoApi } from 'api/knowledge'
import { Toast } from 'vant'
import { whitePageList } from 'utils/auth'
import MapApi from 'api/map'

const _whiteList = [...whitePageList, 'OfflineActivitiesSign']
export const useUserStore = defineStore({
  id: 'userStore',
  state: () => {
    return {
      userInfo: {
        avatar: '',
        userName: '',
        phoneNumber: '',
        nickName: ''
      },
      collectionVideoList: [],
      questionFill: 'filled', // fill 没填, filled 填过,  没填但关闭了 closed
      mapTime: '', // 地图更新时间
      noticeStr: '本页面仅在项目所知的范围内，列明了有贝利尤单抗供应的医院信息，供您参考。如您需要确认您是否可以用药，请前往医院就诊并在获得处方后遵医嘱进行用药。如果您想要了解药品供应的确切情况，建议您出行前与相关医疗机构进行确认，感谢您的配合！',
      questionEndTime: ''
    }
  },
  actions: {
    async getUserInfo () {
      const { data } = await UserApi.info()
      this.userInfo = data
      return data
    },
    async editUserInfo (data) {
      await UserApi.edit(data)
      this.getUserInfo()
      Toast('修改成功 !')
    },
    async getCollectionVideo ({ pageNum, pageSize = 10 }) {
      const { rows, total } = await ShortVideoApi.listByCollection({ pageNum, pageSize })
      pageNum === 1 ? (this.collectionVideoList = rows) : this.collectionVideoList.push(...rows)
      return { rows, total }
    },
    setQuestionFill (route) {
      const date = this.userInfo.showStartTime
      const now = date && new Date(date.replace(/-/g, '/'))
      if (now) {
        // iOS 系统对 JavaScript 中的 new Date() 方法有严格的格式要求
      // "2020-08-08 00:00" 会返回valid Date 正确格式因为2020/08/08 00:00
        this.questionEndTime = format(addDays(new Date(now), 8), 'yyyy-MM-dd')
        let _questionFill = ''
        if (!_whiteList.includes(route.name) && this.userInfo.isQuestion === 0 &&
      (new Date(this.questionEndTime).getTime()) > new Date(format(new Date(), 'yyyy-MM-dd')).getTime()) {
        // 关闭了
          _questionFill = localStorage.getItem('QUESTION-FILL-DATE') === format(new Date(), 'yyyy-MM-dd') ? 'closed' : 'fill'
        } else {
          _questionFill = 'filled'
        }
        this.questionFill = _questionFill
        // 记录
        localStorage.setItem('QUESTION-FILL-DATE', format(new Date(), 'yyyy-MM-dd'))
      }
    },
    async randomNick () {
      const { data } = await UserApi.randomName()
      return data
    },
    async getMapTime () {
      const { msg } = await MapApi.getUpdateTime()
      this.mapTime = msg
    }
  }
})
