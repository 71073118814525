import axios from 'axios'
import { Toast } from 'vant'
import { getToken, goLogin } from 'utils/auth'

const service = axios.create({
  baseURL: '/service-bly-mp/mp',
  withCredentials: true,
  timeout: 16000
})
const codeMessage = {
  400: '请求错误',
  401: '未授权，请登录',
  403: '拒绝访问',
  404: '请求地址出错',
  408: '请求超时',
  500: '服务器正在努力工作中，请稍后重试',
  501: '服务器正在努力工作中，请稍后重试',
  502: '服务器正在努力工作中，请稍后重试',
  503: '服务器正在努力工作中，请稍后重试',
  504: '服务器正在努力工作中，请稍后重试',
  505: '服务器正在努力工作中，请稍后重试',
  20004: '未找到对应结果'
}
// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.url == '/userIndexRecord/exportUserIndexRecord') {
      return Promise.resolve(res)
    }
    const { code, msg } = res
    if (code && code === 200) {
      return Promise.resolve(res)
    } else {
      // 登录超时,重新登录
      if (code === 401) {
        goLogin()
      } else {
        Toast.fail(msg)
      }
      return Promise.reject(res || 'error')
    }
  },
  err => {
    // 网络连接正常
    console.log(err, 'err.response.status', err.response)
    if (err.response) {
      err.message = codeMessage[err.response.status]
    }
    err.message && Toast.fail(err.message)
    return Promise.reject(err)
  }
)

export default service
