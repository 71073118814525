import { areaList } from '@vant/area-data'
import { statisticsApi } from 'api/common'
import { wxShare } from 'utils/wx'
import { isAfter, isBefore, addDays } from 'date-fns'

/**
 * 判断是否为微信环境
 */
export const isWeiXin = window.navigator.userAgent.indexOf('MicroMessenger') > -1

/**
 * @param remove{String}
 * @returns {Object}
 */
export function removeQueryString (name, url = window.location.href) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)')
  const r = window.location.search.substr(1).match(reg)
  const str = unescape(r[0]) || ''
  const href = url.replace(str, '')
  return href
}

// 替换当前历史记录 (url必须和当前页面url是同源的，否则不生效)
export function locationReplace (url) {
  if (window.history.replaceState) {
    window.history.replaceState(null, document.title, url)
    window.history.go(0)
  } else {
    window.location.replace(url)
  }
}
function checkURL (URL) {
  const str = URL
  // 判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  // 下面的代码中应用了转义字符"\"输出一个字符"/"
  const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
  const objExp = new RegExp(Expression)
  if (objExp.test(str) == true) {
    return true
  } else {
    return false
  }
}

// 地址拼接域名
export function imgUrl (url) {
  // return import.meta.env.VITE_BASE_URL + url
  if (checkURL(url)) {
    return url
  } else {
    return 'http://imgcdn.bjhhcare.com/' + url
  }
}

// 根据城市code获取省市名称
export function provinceCityName (cityCode) {
  const provinceCode = Math.floor(cityCode / 10000) * 10000
  return `${areaList.province_list[provinceCode]} ${areaList.city_list[cityCode]}`
}

// 页面增加访问记录
export function statisticsPages (data) {
  // console.log(data, 'data')
  statisticsApi.accessRecord(data).then(res => {
    console.log('访问记录')
  })
}

export function getUrlParam (name, href) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象

  const r = window.location.search.substr(1).match(reg) // 匹配目标参数

  if (r != null) return unescape(r[2])

  return null
}

export function goShare (data) {
  const shareType = ['wechat', 'wxline', 'mjFriend', 'link']
  // console.log(window.mh)
  if (isWeiXin) {
    wxShare(data)
  } else {
    window.mh && mh.onShareConfig({
      type: shareType,
      enable: 1,
      ...data,
      success: function (res) {
        console.log(res, '成功')
      },
      fail: function (res) {
        console.log(res, '失败')
      }
    })
  }
}

export function setShareLink (to) {
  let location = window.location.origin + to.path + '?'
  let query = ''
  for (const key in to.query) {
    if (!key) {
      console.log(key)
    } else {
      if (key == '_date') {
        query = query + `${key}=${+new Date()}&`
      } else if (key == 'isQuestion') {
      } else {
        query = query + `${key}=${to.query[key]}&`
      }
    }
  }
  location = location + query
  if (to.meta.share && !getUrlParam('isForward', location)) {
    location += 'isForward=1&'
  }
  if (!isWeiXin) {
    if (!getUrlParam('share_status', location)) {
      location += 'share_status=show&'
    }
    if (!getUrlParam('mijian', location)) {
      location += 'mijian=1&'
    }
  }

  if (!getUrlParam('_date', location)) {
    location += `_date=${+new Date()}`
  }
  return location
}

export function checkNew (val) {
  const formatIos = val && new Date(val.replace(/-/g, '/'))
  const startDay = new Date(formatIos)
  const today = new Date()
  const afterDay = addDays(startDay, 7)
  // console.log(format(new Date(afterDay), 'yyyy-MM-dd hh:mm:ss'))
  const isAfterSevenDays = isBefore(today, afterDay) && isAfter(startDay, today)
  return isAfterSevenDays
}

export function IsMobile () {
  // 获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
  const info = navigator.userAgent
  // 通过正则表达式的test方法判断是否包含“Mobile”字符串
  const isPhone = !!info.match(/AppleWebKit.*Mobile.*/)
  // 如果包含“Mobile”（是手机设备）则返回true
  return isPhone
}
