import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useUserStore } from 'store/user'
import { setToken, whitePageList, removeToken } from 'utils/auth'
import { removeQueryString, isWeiXin, statisticsPages, goShare, setShareLink } from 'utils/index'
import { wxInit } from 'utils/wx'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

const share = (to) => {
  const location = setShareLink(to)
  goShare({
    title: '狼疮科普知识',
    desc: '狼疮疾病科普精彩分享，不容错过哦',
    link: location,
    imageUrl: 'https://imgcdn.mijian360.com/uploads/images/2024/0124/K5LOkXuwIGKEYPCio4.png'
  })
}

router.beforeEach(async (to, from, next) => {
  // 只能在微信客户端打开
  // if (to.name == '404') {
  //   next({ name: '404', query: { code: '428' }, replace: true })
  //   return
  // }
  // if (!to.query.system && !sessionStorage.getItem('system')) {
  //   if (to.path != '/upgradation') {
  //     next({ path: '/upgradation' })
  //   } else {
  //     next()
  //   }
  //   return
  // } else {
  //   sessionStorage.setItem('system', 1)
  //   // new VConsole()
  // }

  const { meta: { title = '蓓加呵护' }, query: { token = '', status, openId = '', notFollow = '', url = '', mijian = '' }} = to // status 0未提交审核 1待审核 2审核通过,  openId 未审核才有, token审核通过才有，
  document.title = title
  if (mijian == 1 && !isWeiXin) {
    console.log('mijian', mijian)
    removeToken()
  }
  if (notFollow) {
    next({
      name: 'Transit',
      query: { follow: notFollow },
      redirect: '/transit',
      replace: true
    })
    return
  }
  !!token && setToken(token)
  // isQuestion  0 是未填写， 1是已填写, 只有填写了问卷才能用
  if (whitePageList.includes(to.name)) {
    next()
    return
  }
  // 微信登陆后携带的token
  if (status == 2) {
    const removeTokenHref = removeQueryString('token', window.location.href)
    const href = removeQueryString('status', removeTokenHref)
    // locationReplace(href)
    // if (getUrlParam('url')) {
    //   window.location.replace(getUrlParam('url'))
    // } else {
    //   window.location.replace(href)
    // }
    window.location.replace(href)
    return
  }
  // 如果是线下活动， 未通过审核签到
  if (['1', '0'].includes(status) && to.name === 'OfflineActivitiesSign') {
    next()
  }
  // 未提交审核
  if (status == 0) {
    next({
      name: 'ProjectIntroduce',
      query: { openId },
      redirect: '/project/introduce',
      replace: true
    })
    return
  }
  // 正在审核中
  if (status == 1) {
    next({
      name: 'ProjectSuccess',
      redirect: '/project/success',
      replace: true
    })
    return
  }
  const { userInfo, getUserInfo, setQuestionFill } = useUserStore()
  // 已有token, 但未获取用户信息
  if (to.path != '/transit') {
    !userInfo.userId && await getUserInfo()
  }

  setQuestionFill(to)
  // 地图列表 搜索 用药科普 keepalive 状态更新
  if (to.path == '/map/list' && from.path != '/map/view') {
    sessionStorage.setItem('refhresh', true)
  }
  if (to.path == '/search/index' && from.path == '/home/knowledge') {
    sessionStorage.setItem('refhresh', true)
  }
  if (to.path == '/search/science' && from.path == '/home/knowledge') {
    sessionStorage.setItem('refhresh', true)
  }
  next()
})

router.afterEach((to, from) => {
  if (isWeiXin) {
    if (to.path != '/transit' && (!to.meta.share)) {
      wxInit()
    }
  }
  if (!isWeiXin) {
    share(to)
  } else {
    if (to.meta.share) {
      share(to)
    }
  }
  console.log('to', to)
  // 页面访问统计
  const type = to.meta.type
  let data = {
    type,
    accessSource: isWeiXin ? 1 : 2
  }
  // console.log(to.params.id)
  if (to.params.id) {
    data = {
      type,
      typeIndex: to.params.id,
      accessSource: isWeiXin ? 1 : 2
    }
  }
  type && statisticsPages(data)
})

export default router
