import request from 'utils/request'

export class RotationApi {
  /**
   * @description 轮播图
   * @params { location } // 轮播图位置:1.首页2.科普文章3.病友故事4.专家视频5.线上讲座
   * @returns { Array }
   */
  static list (params) {
    return request.get('/rotation/list', { params })
  }
}

export class ShortVideoApi {
  /**
   * @description 专家视频列表
   * @params { pageNum, pageSize }
   * @returns { Array }
   */
  static list (params) {
    return request.get('/shortvideo/list', { params })
  }

  /**
   * @description 获取专家视频详细信息
   * @params { shortvideoId }
   * @returns { Object }
   */
  static detail (shortvideoId, params) {
    return request.get('/shortvideo/' + shortvideoId, { params })
  }

  /**
   * @description 点赞专家视频
   * @params { operator, shortvideoId }
   * @returns null
   */
  static like (params) {
    return request.get('/shortvideo/like', { params })
  }

  /**
   * @description 收藏专家视频
   * @params { operator, shortvideoId }
   * @returns null
   */
  static collection (params) {
    return request.get('/shortvideo/collection', { params })
  }

  /**
   * @description 收藏列表
   * @params { pageNum, pageSize }
   * @returns null
   */
  static listByCollection (params) {
    return request.get('/shortvideo/listByCollection', { params })
  }

  /**
   * @description 通过用药阶段标签编码查询列表（不分页)
   * @params { tagCodes }
   * @returns { Array }
   */
  static listByCode (params) {
    return request.get('/shortvideo/noPageListByTagCodes', { params })
  }
  /**
     * @description 视频时长记录
     * @params { tagCodes }
     * @returns { Array }
     */
  static shortvideoPlayRecord (data) {
    return request.post('/shortvideoPlayRecord', data)
  }
  /**
     * @description 新增转发记录
     * @params { tagCodes }
     * @returns { Array }
     */
  static forwardRecordInfo (data) {
    return request.post('/forwardRecord', data)
  }
}

export class OnLiveApi {
  /**
   * @description 线上讲座列表
   * @params { pageNum, pageSize }
   * @returns { Array }
   */
  static list (params) {
    return request.get('/onLive/list', { params })
  }

  /**
   * @description 获取线上讲座详细信息
   * @params { onLiveId }
   * @returns { Object }
   */
  static detail (onLiveId) {
    return request.get('/onLive/' + onLiveId)
  }
  /**
   * @description 通过用药阶段标签编码查询列表（不分页)
   * @params { tagCodes }
   * @returns { Array }
   */
  static listByCode (params) {
    return request.get('/onLive/noPageListByTagCodes', { params })
  }
}

export class StoryApi {
  /**
   * @description 患者故事列表
   * @params { pageNum, pageSize }
   * @returns { Array }
   */
  static list (params) {
    return request.get('/story/list', { params })
  }

  /**
   * @description 获取患者故事详细信息
   * @params { storyId }
   * @returns { Object }
   */
  static detail (storyId, params) {
    return request.get('/story/' + storyId, { params })
  }

  /**
   * @description 点赞患者故事
   * @params { operator, storyId }
   * @returns null
   */
  static like (params) {
    return request.get('/story/like', { params })
  }

  /**
   * @description 通过用药阶段标签编码查询列表（不分页)
   * @params { tagCodes }
   * @returns { Array }
   */
  static listByCode (params) {
    return request.get('/story/noPageListByTagCodes', { params })
  }
}

export class ArticleApi {
  /**
   * @description 科普文章列表
   * @params { pageNum, pageSize }
   * @returns { Array }
   */
  static list (params) {
    return request.get('/article/list', { params })
  }

  /**
   * @description 获取科普文章详细信息
   * @params { articleId }
   * @returns { Object }
   */
  static detail (articleId, params) {
    return request.get('/article/' + articleId, { params })
  }

  /**
   * @description 点赞科普文章
   * @params { operator, articleId }
   * @returns null
   */
  static like (params) {
    return request.get('/article/like', { params })
  }

  /**
   * @description 收藏科普文章
   * @params { operator, articleId }
   * @returns null
   */
  static collection (params) {
    return request.get('/article/collection', { params })
  }

  /**
   * @description 收藏列表
   * @params { pageNum, pageSize }
   * @returns null
   */
  static listByCollection (params) {
    return request.get('/article/listByCollection', { params })
  }

  /**
   * @description 通过用药阶段标签编码查询列表（不分页)
   * @params { tagCodes }
   * @returns { Array }
   */
  static listByCode (params) {
    return request.get('/article/noPageListByTagCodes', { params })
  }
}

export class ActivityApi {
  /**
   * @description 查询活动详情
   * @params { activityId }
   * @returns { Object }
   */
  static detail (activityId) {
    return request.get('/activity/detail/' + activityId)
  }

  /**
   * @description 报名签到
   * @params { activityId }
   * @returns { Object }
   */
  static sign (activityId) {
    return request.get('/activity/admission/' + activityId)
  }

  /**
   * @description 报名活动
   * @params { activityId }
   * @returns { Object }
   */
  static join (activityId) {
    return request.get('/activity/join/' + activityId)
  }
}
