<!-- eslint-disable vue/no-deprecated-v-is -->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name" />
  </router-view>
  <van-tabbar
    v-if="$route.meta.tabbar" route :safe-area-inset-bottom="true" active-color="#32DAE2" class="tabbar-list">
    <div class="btn">
      <wx-open-subscribe
        v-if="isWeiXin && IsMobile()" id="launch-btn" template="OUAfc2r_zkkXZ_sOJm5Wp_gKc0YyxgNl2AROvAkesfY"
        @success="(e) => subscribeSuccess(e, 'Knowledge')" @error="(e) => subscribeError(e, 'Knowledge')" @click="handelClick">
        <div v-is="'script'" type="text/wxtag-template">
          <div style="position: absolute;width: 100%;height: 100%;" />
        </div>
      </wx-open-subscribe>
      <van-tabbar-item replace to="/home/knowledge" class="tabbar-item" style="height: 100%;">
        知识热点
        <template #icon="props">
          <svg-icon :icon-class="props.active ? 'tabbar-knowledge-active' : 'tabbar-knowledge'" class="icon" />
        </template>
      </van-tabbar-item>
    </div>
    <div class="btn">
      <wx-open-subscribe
        v-if="isWeiXin && IsMobile()" id="launch-btn" template="OUAfc2r_zkkXZ_sOJm5Wp_gKc0YyxgNl2AROvAkesfY"
        @success="(e) => subscribeSuccess(e, 'HealthManage')" @error="(e) => subscribeError(e, 'HealthManage')" @click="handelClick">
        <div v-is="'script'" type="text/wxtag-template">
          <div style="position: absolute;width: 100%;height: 100%;" />
        </div>
      </wx-open-subscribe>
      <van-tabbar-item replace to="/home/healthManage" class="tabbar-item" style="height: 100%;">
        健康管理
        <template #icon="props">
          <svg-icon :icon-class="props.active ? 'tabbar-health-active' : 'tabbar-health'" class="icon" />
        </template>
      </van-tabbar-item>
    </div>
    <div class="btn">
      <wx-open-subscribe
        v-if="isWeiXin && IsMobile()" id="launch-btn" template="OUAfc2r_zkkXZ_sOJm5Wp_gKc0YyxgNl2AROvAkesfY"
        @success="(e) => subscribeSuccess(e, 'TopicList')" @error="(e) => subscribeError(e, 'TopicList')" @click="handelClick">
        <div v-is="'script'" type="text/wxtag-template">
          <div style="position: absolute;width: 100%;height: 100%;" />
        </div>
      </wx-open-subscribe>
      <van-tabbar-item replace to="/home/topic" class="tabbar-item" style="height: 100%;">
        主题活动
        <template #icon="props">
          <svg-icon :icon-class="props.active ? 'tabbar-topic-active' : 'tabbar-topic'" class="icon" />
        </template>
      </van-tabbar-item>
    </div>
    <div class="btn">
      <wx-open-subscribe
        v-if="isWeiXin && IsMobile()" id="launch-btn" template="OUAfc2r_zkkXZ_sOJm5Wp_gKc0YyxgNl2AROvAkesfY"
        @success="(e) => subscribeSuccess(e, 'UserCenter')" @error="(e) => subscribeError(e, 'UserCenter')" @click="handelClick">
        <div v-is="'script'" type="text/wxtag-template">
          <div style="position: absolute;width: 100%;height: 100%;" />
        </div>
      </wx-open-subscribe>
      <van-tabbar-item replace to="/home/userCenter" class="tabbar-item" style="height: 100%;">
        个人中心
        <template #icon="props">
          <svg-icon :icon-class="props.active ? 'tabbar-user-active' : 'tabbar-user'" class="icon" />
        </template>
      </van-tabbar-item>
    </div>
  </van-tabbar>
  <!-- 填写问卷 -->
  <!-- <img v-if="!question && !_whiteList.includes($route.name)" src="../assets/svg/icon-write.svg" alt="问卷" class="question-icon" @click="handleGoQuestion">
  <van-dialog
    v-model:show="showQuestion"
    title=""
    confirm-button-text="填写调查问卷"
    confirm-button-color="#45A6FF"
    width="300px"
    :close-on-popstate="true"
    @confirm="handleGoQuestion"
  >
    <div class="question-dialog-tip">
      <h4>亲爱的患友：</h4>
      为了能够了解您的需求并为您提供切实的帮助，我们诚邀您抽 1 分钟的宝贵时间，填写以下几个简单的问题，我们将根据您的建议更加合理地规划平台上的功能及科普内容，希望能够为您提供“蓓加呵护”的服务，帮助您更全面地管理疾病，祝您早日回归美好生活！
    </div>
    <van-icon name="cross" @click="handleClose" />
  </van-dialog> -->
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import { useUserStore } from 'store/user'
import { isWeiXin, IsMobile } from 'utils/index'
import { whitePageList } from 'utils/auth'
const _whiteList = [...whitePageList, 'OfflineActivitiesSign']
const userStore = useUserStore()

const $route = useRoute()

const showQuestion = ref(false)
const question = ref(0)
watch(() => userStore.questionFill, (newVal) => {
  showQuestion.value = newVal === 'fill'
}, { immediate: true, deep: true })

watch(() => userStore.userInfo.isQuestion, (newVal) => {
  question.value = newVal
}, { immediate: true, deep: true })

const handleGoQuestion = () => {
  router.replace({
    name: 'Question',
    query: {
      redirect: $route.path
    }
  })
}

const handleClose = () => {
  userStore.setQuestionFill($route)
}
const subscribeSuccess = (e, path) => {
  console.log(e)
  router.replace({
    name: path
  })
}

const subscribeError = (e, path) => {
  console.log(e)
  router.replace({
    name: path
  })
}
</script>
<style lang="less" scoped>
.icon {
  width: 52px;
  height: 52px;
}
.tabbar-list {
  height: 110px;
}
.question-dialog-tip {
  line-height: 42px;
  letter-spacing: 1px;
  padding: 55px;
  h4 {
    margin: 0 0 10px;
    color: #333;
  }
}
.van-icon-cross{
  position: absolute;
  right: 20px;
  top: 20px;
  color: #939393;
}
.question-icon {
  width: 124px;
  height: 124px;
  position: fixed;
  z-index: 99;
  right: 30px;
  bottom: 220px;
}
#launch-btn{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: auto;
}
.btn{
  flex: 1;
  height: 100%;
  position: relative;
  z-index: 22;
}
</style>
