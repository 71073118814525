import request from 'utils/request'

export default class UserApi {
  /**
   * @description 获取基础信息
   * @params null
   * @returns { Object }
   */
  static info (params) {
    return request.get('/user', { params })
  }

  /**
   * @description 修改基础信息（不能修改处方单和发票图片）
   * @params { cityId, openId, phoneNumber, userName, code, actionType, userId }
   * @returns null
   */
  static edit (data) {
    return request.put('/user', data)
  }

  /**
   * @description 资料审核
   * @params { cityId, openId, phoneNumber, userName, code, actionType, userFiles }
   * @returns null
   */
  static toAudit (data) {
    // 2024/1/17 /service-bly-mp/mp/user/toAudit 变更为 /service-bly-mp/mp/auth/toAudit
    return request.put('/auth/toAudit', data)
  }

  /**
   * @description 是否同意协议
   * @params { openId }
   * @returns null
   */
  static isAgree (params) {
    // 2024/1/17 /service-bly-mp/mp/fan/isAgree 变更为 /service-bly-mp/mp/auth/isAgree
    return request.get('/auth/isAgree', { params })
  }

  /**
   * @description 随机获取昵称
   * @params { openId }
   * @returns null
   */
  static randomName (params) {
    return request.get('/user/getRandomNickName', { params })
  }

  // 修改资料审核图片
  static changeUserFiles (data) {
    return request.put('/user/changeUserFiles', data)
  }
}
