// import wx from 'weixin-js-sdk'
import WechatApi from 'api/wechat'
import { isWeiXin } from './index'
import { set } from 'date-fns'

const jsApiList = [
  'hideMenuItems', 'closeWindow', 'openLocation', 'updateAppMessageShareData', 'updateTimelineShareData', 'showMenuItems'
]
export async function wxInit (showMenu = false) {
  if (!isWeiXin) return
  const { data: { appid, noncestr, signature, timestamp }} = await WechatApi.jsticket({
    url: window.location.href
  })
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: appid, // 和获取Ticke的必须一样------必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: noncestr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名
    jsApiList: jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    openTagList: ['wx-open-subscribe', 'wx-open-subscribe-dialog'] // 微信开放标签
  })
  // 隐藏右上角按钮
  wx.ready(function () {
    if (!showMenu) {
      wx.hideOptionMenu()
    }
    // wx.hideMenuItems({
    //   menuList: [
    //     'menuItem:share:appMessage',
    //     'menuItem:share:timeline',
    //     'menuItem:share:qq',
    //     'menuItem:share:weiboApp',
    //     'menuItem:share:QZone',
    //     'menuItem:copyUrl',
    //     'menuItem:openWithQQBrowser',
    //     'menuItem:openWithSafari'
    //   ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
    // })
  })
}

// 微信分享
export async function wxShare ({
  title = '狼疮科普知识',
  desc = '狼疮疾病科普精彩分享，不容错过哦',
  link = window.location.href,
  imageUrl = ''
} = {}) {
  await wxInit(true)
  setTimeout(() => {
    wx.ready(function () {
      wx.showMenuItems({
        menuList: ['menuItem:share:appMessage',
          'menuItem:share:timeline']
      })
      // 分享给朋友”及“分享到QQ”
      wx.updateAppMessageShareData({
        title, // 分享标题
        desc,
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imageUrl, // 分享图标
        success: function (res) {
          console.log('分享成功-----------------', res)
        },
        fail: (res) => {
          console.log('分享失败-----------------', res)
        }
      })
      // “分享到朋友圈”及“分享到QQ空间”
      wx.updateTimelineShareData({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imageUrl, // 分享图标
        success: function () {
          // Toast.success('分享成功')
        }
      })
    })
  }, 500)
  wx.error(function (res) {
    // Toast(JSON.stringify(res))
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  })
}
// 地图打开地址
export async function wxOpenLocation ({ latitude, longitude, name, address } = {}) {
  await wxInit()
  wx.ready(function () {
    wx.openLocation({
      latitude: parseFloat(latitude), // 纬度，浮点数，范围为90 ~ -90
      longitude: parseFloat(longitude), // 经度，浮点数，范围为180 ~ -180。
      name: name, // 位置名
      address: address, // 地址详情说明
      scale: 16, // 在查看位置界面底部显示的超链接,可点击跳转
      success: function (res) {
        // alert(res.errMsg)
      },
      fail: function (res) {
        alert(res.errMsg)
      }
    })
  })
  wx.error(function (res) {
    // Toast(JSON.stringify(res))
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  })
}
