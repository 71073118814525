import { isWeiXin, removeQueryString, getUrlParam } from 'utils/index'
export const ACCESS_TOKEN = 'token'

export const whitePageList = [
  '404', 'ProjectIntroduce', 'ProjectSuccess', 'ProjectAgreement', 'AgreementPrivacy', 'AgreementSensitivity', 'InformedConsent', 'UserRegister', 'UserLogin', 'UserAgreement'
] // 白名单

export function getToken () {
  return localStorage.getItem(ACCESS_TOKEN)
}

export function setToken (token) {
  return localStorage.setItem(ACCESS_TOKEN, token)
}

export function removeToken () {
  localStorage.removeItem(ACCESS_TOKEN)
}

export async function goLogin (redirectUrl = window.location.href) {
  if (isWeiXin) {
    window.location.href = import.meta.env.VITE_API_URL + '/service-bly-mp/mp/wechat/getToken?url=' + redirectUrl
  } else {
    let token = null
    let href = ''
    if (getUrlParam('access_token', redirectUrl)) {
      const removeTokenHref = removeQueryString('access_token', redirectUrl)
      if (getUrlParam('mijian', removeTokenHref)) {
        href = removeQueryString('mijian', removeTokenHref)
      }
    } else {
      if (getUrlParam('mijian', redirectUrl)) {
        href = removeQueryString('mijian', redirectUrl)
      }
    }
    // app会自动在链接后面拼接access_token 导致链接过长跳转失败

    console.log('removeTokenHref', href)
    if (window.mh && window.mh.getToken) {
      token = await new Promise((resolve, reject) => {
        function callback () {
          mh.getToken(function (res) {
            console.log('res', res)
            resolve(res.token)
          })
        }
        if (window.WebViewJavascriptBridgeIsReady || window.WebViewJavascriptBridge) {
          callback()
        } else {
          if (!getBrowserInfo().android) {
            window.WVJBCallbacks = window.WVJBCallbacks || []
            window.WVJBCallbacks.push(callback)
          } else {
            // window.onWebViewJavascriptBridgeReady = callback
            document.addEventListener('WebViewJavascriptBridgeReady', function () {
              callback()
            })
          }
        }
      })
      // console.log('href', href)
      sessionStorage.setItem('m_token', token)
      window.location.replace(import.meta.env.VITE_API_URL + '/service-bly-mp/mp/auth/getToken/mjApp?url=' + encodeURIComponent(href) + '&token=' + token)
    }
    // window.location.href = import.meta.env.VITE_API_URL + '/service-bly-mp/mp/auth/getToken/mjApp?url=' + redirectUrl + '&token=' + token
  }
}
