import request from 'utils/request'

export default class WechatApi {
  /**
   * @description H5微信页面授权获取token
   * @params { url  }
   * @returns 重定向
   */
  static getToken (params) {
    return request.get('/wechat/getToken', { params })
  }

  /**
   * @description H5微信获取jsskd信息
   * @params { url  }
   * @returns { Object }
   */
  static jsticket (params) {
    return request.get('/wechat/jsticket', { params })
  }
}
