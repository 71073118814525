import request from 'utils/request'
export default class MapApi {
  static getList (params) {
    return request.get('/medicationMap/list', { params })
  }
  static getDetail (id) {
    return request.get('/medicationMap/' + id)
  }
  static getUpdateTime () {
    return request.get('/medicationMap/getUpdateTime')
  }
  static getNoPageList (params) {
    return request.get('/medicationMap/noPageList', { params })
  }
}
